<template>
  <b-container fluid class="overflowable">
    <b-row>
      <b-col>
        <incident-open-list />
        <edit-incident-modal />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import IncidentOpenList from "@/components/incident/IncidentOpenList.vue";
import EditIncidentModal from "@/components/incident/EditIncidentModal.vue";

export default {
  components: {
    IncidentOpenList,
    EditIncidentModal
  }
};
</script>
