<template>
  <div>
    <b-row>
      <b-col>
        <div class="py-2">
          <b-button @click="onOpenIncidentModal">
            {{ $t("incident.add-incident-btn") }}
          </b-button>
          <b-button class="ml-2" @click="$router.push({ name: 'incident-history' })">
            {{ $t("incident.open-history-btn") }}
          </b-button>
          <b-button class="ml-2" @click="onExport" :disabled="isExporting">
            {{ $t("incident.excel-export") }}
            <b-spinner v-if="isExporting" small label="Exporting..."></b-spinner>
          </b-button>
        </div>
        <div class="my-2">
          <b-checkbox
            switch
            :checked="filter.isShowOnlyMyIncidents"
            @input="onChangeFilterValue(filter, 'isShowOnlyMyIncidents', $event, loadIncidents)"
          >
            {{ $t("incident.show-only-my") }}
          </b-checkbox>
          <b-checkbox
            switch
            :checked="filter.isShowOnlyOpenIncidents"
            @input="onChangeFilterValue(filter, 'isShowOnlyOpenIncidents', $event, loadIncidents)"
          >
            {{ $t("incident.show-only-open") }}
          </b-checkbox>
        </div>
        <b-pagination
          :value="pagination.currentPage"
          :total-rows="pagination.totalCount"
          :per-page="pagination.limit"
          @input="onChangeCurrentPage($event, loadIncidents)"
        ></b-pagination>
        <b-table
          no-local-sorting
          responsive
          :fields="fields"
          :items="$store.getters['incidents/incidents']"
          :sort-desc.sync="sortDesc"
          :sort-by.sync="sortBy"
          :style="{ height: this.$store.availableTableHeight }"
          @sort-changed="onTableSortChanged($event, loadIncidents)"
        >
          <template v-slot:top-row>
            <td></td>
            <td>
              <b-input
                :value="filter.title"
                @change="onChangeFilterValue(filter, 'title', $event, loadIncidents)"
              ></b-input>
            </td>
            <td>
              <b-input
                :value="filter.registrationNumber"
                @change="onChangeFilterValue(filter, 'registrationNumber', $event, loadIncidents)"
              ></b-input>
            </td>
            <td>
              <b-datepicker
                value-as-date
                reset-button
                :value="filter.reportedAt"
                :locale="$i18n.locale"
                :label-reset-button="$t('datepicker.reset-btn')"
                @input="onChangeFilterValue(filter, 'reportedAt', $event, loadIncidents)"
                placeholder=""
                label-no-date-selected=""
                boundary="window"
              ></b-datepicker>
            </td>
            <td>
              <b-input
                :value="filter.reportedBy"
                @change="onChangeFilterValue(filter, 'reportedBy', $event, loadIncidents)"
              ></b-input>
            </td>
            <td>
              <b-select
                :options="incidentTypeOptions"
                @input="onChangeFilterValue(filter, 'type', $event, loadIncidents)"
              ></b-select>
            </td>
            <td>
              <b-select
                :value="filter.status"
                :options="incidentStatusOptions"
                @input="onChangeFilterValue(filter, 'status', $event, loadIncidents)"
              ></b-select>
            </td>
            <td>
              <b-input
                :value="filter.incidentResponsibleUser"
                @change="onChangeFilterValue(filter, 'incidentResponsibleUser', $event, loadIncidents)"
              ></b-input>
            </td>
            <td>
              <b-datepicker
                value-as-date
                reset-button
                :value="filter.modifiedAt"
                :locale="$i18n.locale"
                :label-reset-button="$t('datepicker.reset-btn')"
                @input="onChangeFilterValue(filter, 'modifiedAt', $event, loadIncidents)"
                placeholder=""
                label-no-date-selected=""
                boundary="window"
              ></b-datepicker>
            </td>
            <td>
              <b-input
                :value="filter.modifiedBy"
                @change="onChangeFilterValue(filter, 'modifiedBy', $event, loadIncidents)"
              ></b-input>
            </td>
            <td>
              <b-input
                :value="filter.responsibleUser"
                @change="onChangeFilterValue(filter, 'responsibleUser', $event, loadIncidents)"
              ></b-input>
            </td>
            <td></td>
          </template>
          <template v-slot:cell(isUrgent)="{ value }">
            <img v-if="value" src="/images/warning.png" class="mx-auto d-block" />
          </template>
          <template v-slot:cell(vehicleRegistrationNumber)="row">{{ row.item.vehicle.registrationNumber }}</template>
          <template v-slot:cell(reportedAt)="row">{{ $d(new Date(row.item.reportedAt), "log") }}</template>
          <template v-slot:cell(reportedBy)="row">
            {{ row.item.reportedBy.userName }}
            <phone-number :phoneNumber="row.item.reportedBy.phone"></phone-number>
          </template>
          <template v-slot:cell(type)="row">{{ translateType(row.item.type, row.item.issueCategoryId) }}</template>
          <template v-slot:cell(status)="row">{{ translateStatus(row.item.status) }}</template>
          <template v-slot:cell(incidentResponsibleUser)="row">
            {{ row.item.incidentResponsibleUser == null ? "" : row.item.incidentResponsibleUser.userName }}
          </template>
          <template v-slot:cell(modifiedAt)="row">{{ $d(new Date(row.item.modifiedAt), "log") }}</template>
          <template v-slot:cell(modifiedBy)="row">
            {{ row.item.modifiedBy.userName }}
            <phone-number :phoneNumber="row.item.modifiedBy.phone"></phone-number>
          </template>
          <template v-slot:cell(responsibleUser)="row">
            {{ row.item.responsibleUser == null ? "" : row.item.responsibleUser.userName }}
          </template>
          <template v-slot:cell(files)="row"><img src="/images/check.svg" v-if="row.value.length > 0"/></template>
          <template v-slot:cell(title)="row">
            <b-link @click="openIncident(row.item.id)">{{ row.item.title }}</b-link>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PhoneNumber from "@/components/PhoneNumber.vue";

import auth from "@/services/auth.js";
import file from "@/services/file.js";

import { DRIVER } from "@/const/user-role.js";
import { CUSTOM_INCIDENT_TYPE } from "@/const/incident-types.js";
import * as INCIDENT_STATUSES from "@/const/incident-statuses.js";
import { OPEN_EDIT_INCIDENT_MODAL, UPDATE_INCIDENT_LIST, OPEN_NEW_INCIDENT_MODAL } from "@/const/events.js";

import incidentTableMixin from "@/mixins/incident-table-mixin.js";

export default {
  mixins: [incidentTableMixin],
  components: {
    PhoneNumber
  },
  data() {
    return {
      sortDesc: false,
      sortBy: null,
      prevSortBy: null,
      filter: {
        isShowOnlyMyIncidents: false,
        isShowOnlyOpenIncidents: false
      },
      isExporting: false
    };
  },
  computed: {
    fields() {
      return [
        {
          key: "isUrgent",
          label: this.$t("incident.is-urgent"),
          sortable: true
        },
        {
          key: "title",
          label: this.$t("incident.title"),
          sortable: true
        },
        {
          key: "vehicleRegistrationNumber",
          label: this.$t("incident.vehicle-registration-number"),
          sortable: true
        },
        { key: "reportedAt", label: this.$t("incident.reported-at"), sortable: true },
        { key: "reportedBy", label: this.$t("incident.reported-by"), sortable: true },
        { key: "type", label: this.$t("incident.type") },
        { key: "status", label: this.$t("incident.status") },

        {
          key: "incidentResponsibleUser",
          label: this.$t("incident.incident-responsible-user"),
          sortable: true
        },

        { key: "modifiedAt", label: this.$t("incident.modified-at"), sortable: true },
        { key: "modifiedBy", label: this.$t("incident.modified-by"), sortable: true },
        {
          key: "responsibleUser",
          label: this.$t("incident.responsible-user"),
          sortable: true
        },
        { key: "files", label: this.$t("incident.files") }
      ];
    },
    incidentStatusOptions() {
      return [
        { value: null, text: "" },
        { value: INCIDENT_STATUSES.OPEN, text: this.$t("incident-status." + INCIDENT_STATUSES.OPEN) },
        { value: INCIDENT_STATUSES.CLOSED, text: this.$t("incident-status." + INCIDENT_STATUSES.CLOSED) },
        { value: INCIDENT_STATUSES.IN_PROCESS, text: this.$t("incident-status." + INCIDENT_STATUSES.IN_PROCESS) },
        {
          value: INCIDENT_STATUSES.NEXT_THING_TODO,
          text: this.$t("incident-status." + INCIDENT_STATUSES.NEXT_THING_TODO)
        }
      ];
    }
  },
  methods: {
    onTableSortChanged(event, loadDataCallback) {
      if (event.sortBy === "isUrgent" && this.prevSortBy !== event.sortBy) {
        event.sortDesc = true;
        this.sortDesc = true;
      }

      this.prevSortBy = event.sortBy;

      this.onSortChanged(event, loadDataCallback);
    },
    translateType(type, issueCategoryId) {
      if (type == CUSTOM_INCIDENT_TYPE) {
        let issueCategory = this.incidentTypeOptions.find(x => x.value.categoryId == issueCategoryId);
        if (issueCategory) return issueCategory.text;
      } else {
        return this.$t(`incident-type.${type}`);
      }
    },
    translateStatus(status) {
      return this.$t(`incident-status.${status}`);
    },
    openIncident(id) {
      let incident = this.$store.getters["incidents/incident"](id);
      let obj = JSON.parse(JSON.stringify(incident));

      if (auth.userRole() === DRIVER) {
        if (incident.status === INCIDENT_STATUSES.OPEN && incident.reportedBy.userName === auth.userName()) {
          this.$bus.emit(OPEN_EDIT_INCIDENT_MODAL, obj);
        } else {
          this.$router.push({ name: "incident", params: { incidentId: id } });
        }
      } else if (incident.status !== INCIDENT_STATUSES.CLOSED) {
        this.$bus.emit(OPEN_EDIT_INCIDENT_MODAL, obj);
      } else {
        this.$router.push({ name: "incident", params: { incidentId: id } });
      }
    },
    loadIncidents() {
      this.$store.dispatch("incidents/getOpenIncidents", this.getSearchParams()).then(resp => {
        this.$store.commit("incidents/setIncidentsState", resp.data.items);
        this.pagination.totalCount = resp.data.totalCount;
      });
    },
    onOpenIncidentModal() {
      this.$bus.emit(OPEN_NEW_INCIDENT_MODAL);
    },
    onExport() {
      this.isExporting = true;
      this.$store
        .dispatch("incidents/export", this.$i18n.locale)
        .then(resp => {
          file.download({ fileName: "incidents.xlsx", content: resp.data });
          return Promise.resolve();
        })
        .finally(() => {
          this.isExporting = false;
        });
    }
  },
  mounted() {
    this.$store.dispatch("issueCategories/load");
    this.loadIncidents();
    this.$bus.on(UPDATE_INCIDENT_LIST, this.loadIncidents);
  },
  beforeDestroy() {
    this.$bus.off(UPDATE_INCIDENT_LIST, this.loadIncidents);
  }
};
</script>
